<template lang="pug">
.ListSeeMore
	span.genre-button.list-item(v-if="genreButton")
		svg.genre-shape(width='83' height='90' viewbox='0 0 83 90' fill='#333333' xmlns='http://www.w3.org/2000/svg')
			g(clip-path='url(#clip0_75:6866)')
				path(fill='333333' d='M50.9188 6.84618C62.1749 -8.78664 50.6321 -29.0976 37.8319 -37.2604C13.5772 -52.7313 -7.55758 -43.754 -28.6297 -18.7155C-49.7034 6.32226 -51.8742 39.171 -33.4739 54.6578C-24.9922 61.7955 -13.3885 66.0743 -4.46916 64.072C9.85259 60.8481 14.1832 46.6326 18.4735 35.4531C24.2097 20.5024 40.8117 20.8838 50.9188 6.84618Z')
		span.title {{ t('genre.seeAll') }}
		SingaIcon.arrow(icon="arrow-forward" size="medium")

	span(v-else-if="listRow")
		.song-list-row
			.song-list-img
				SingaIcon.arrow-icon(icon="arrow-forward" size="small")
			span.song-name.left {{ t('songs.seeAll') }}

	div(v-else :class="isRound ? 'is-rounded' : 'is-rectangular'")
		.default
			SingaIcon(icon="arrow-forward-circle" size="large")
			span.see-more-text  {{ alternativeText ? alternativeText : t('general.seeAll') }}
</template>

<script setup>
const { t } = useI18n()

const props = defineProps({
	isRound: Boolean,
	genreButton: Boolean,
	listRow: Boolean,
	alternativeText: {
		type: String,
		default: '',
		required: false
	}
})
</script>

<style lang="sass" scoped>
.default
	background-color: transparent
	padding: $spacing-16
	width: 100%
	aspect-ratio: 1/1
	border: 1px solid $transparent-white-16
	display: flex
	text-align: center
	align-items: center
	justify-content: center
	flex-direction: column
	.icon
		color: $color-grey-30
	.see-more-text
		padding-top: $spacing-16
		color: $color-grey-30
		display: blockÉ
	&:hover
		.icon, .see-more-text
			color: $color-grey-10
.is-rounded
	padding: 20px
	.default
		border-radius: $radius-round
.is-rectangular
	padding: 12px
	.default
		border-radius: $radius-default
.genre-button
	position: relative
	display: flex
	align-items: center
	justify-content: center
	text-align: center
	width: 100%
	min-height: 128px
	cursor: pointer
	overflow: hidden

.genre-shape
	position: absolute
	top: 0
	left: 0

.title
	@include font(basier, bold)
	@include fontSize(l)
	padding: 8px 0
	margin: 0

.list-item
	background-color: $color-grey-90
	border-radius: $radius-default

.arrow
	margin-left: 0.6em
	color: $color-grey-30

.arrow-icon
	padding: 11px
	color: $color-grey-30
	width: 48px
	height: 48px
	border-radius: $radius-default
	background-color: $color-grey-80
	margin-right: $spacing-16
	margin-left: 0

.song-list-row
	display: inline-block
	border-radius: $radius-default
	width: 100%
	display: flex
	align-items: center
	position: relative
	padding: $spacing-16 0
	@media (min-width: $tablet)
		padding: $spacing-16
		&:hover
			.right
				display: flex
		&:hover, &:focus, &:active
			background-color: $transparent-white-8
	.left
		display: flex
		align-items: center
		justify-content: flex-start
	&::after
		content: ''
		position: absolute
		top: 0
		left: 65px
		right: 0
		width: calc(100% - 65px)
		height: 1px
		background-color: $transparent-white-8
		@media (min-width: $tablet)
			left: 82px
			width: calc(100% - 80px)

	.song-name
		@include font(basier, medium)
		color: $color-grey-30
		overflow: hidden
		white-space: nowrap
		text-overflow: ellipsis
		display: block
</style>
